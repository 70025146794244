a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: rgb(76, 193, 211);
}

.AliceCarousel {
  position: absolute;
}

.alice-carousel__prev-btn-wrapper {
  display: none;
}

.alice-carousel__next-btn-wrapper {
  display: none;
}

.Who {
  text-align: center;
  margin-top: 7%;
  margin-bottom: 7%;
}

.profile-box {
  width: 300px
}
.profile-subbox {
  width: 270px
}

.Profile {
  width: 220px;
  margin-left: 20px;
  margin-bottom: 7%;
}

.Profile2 {
  width: 220px;
  height: 220px;
  margin-bottom: 7%;
  margin-left: 25px;
  border-radius: 50%;
}

.profile-name {
  font-size: 14pt;
  margin-bottom: 30px;
  text-align: center;
}

.personal-contact {
  display:flex;
  flex-direction: row;
  margin-top: 45px;
}

.profile-position {
  text-align: center;
  color: rgb(72, 183, 170);
  font-weight: bold;
  font-size: 11pt;
  margin-top: 10px;
  margin-bottom: 30px;
}

.linkedin-icon {
  margin-right: 5px;
}

.personal-email {
  font-size: 11pt;
  margin-bottom: 15px;
  text-align: center;
}

.profile-explanation {
  text-align: center;
  font-size: 12pt;
}

.slider-img{
  width: 100%;
  height: auto;
  object-fit: cover;
}

.partners {
  margin-left: 45%;
  margin-bottom: 5%;
}

.UNSWpartner {
  margin-left: 30%;
  width: 40vw;
  height: auto;
}

.raregenic-contactUs {
  margin: auto,
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.desktop-profile {
  margin-left: 7%;
  margin-top: 3%;
  margin-bottom: 10%;
  width: 100%;
  overflow: hidden
}
.desktop-cat-profile {
  margin-left: 3%; 
  margin-top: 3%;
  margin-bottom: 10%;
  width: 100%; 
  overflow: hidden
}
.mobile-profile {
  display: none;
  margin-left: 20%;
  margin-top: 3%;
  margin-bottom: 10%;
  width: 100%;
  overflow: hidden
}
.mobile-cat-profile {
  display: none;
  margin-left: 3%;
  margin-top: 3%; 
  margin-bottom: 10%; 
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) and (min-width: 1024px) {
  .desktop-profile {
    display: none;
  }
  .desktop-cat-profile {
    display: none;
  }
  
  .mobile-profile {
    display: block;
    margin-left: 20%;
  }

  .mobile-cat-profile {
    display: block;
    margin-left: 20%;
  }
}

@media screen and (max-width: 1023px) and (min-width: 765px) {
  .desktop-profile {
    display: none;
  }
  .desktop-cat-profile {
    display: none;
  }
  
  .mobile-profile {
    display: block;
    margin-left: 15%;
  }

  .mobile-cat-profile {
    display: block;
    margin-left: 15%;
  }
}

@media screen and (max-width: 764px) {
  .desktop-profile {
    display: none;
  }
  .desktop-cat-profile {
    display: none;
  }
  
  .mobile-profile {
    margin-left: 10%;
    display: block;
  }
  .mobile-cat-profile {
    display: block;
    margin-left: 10%;
  }
  .Who {
    font-size: 10pt;
  }

  .profile-box {
    width: 170px;
  }
  .profile-subbox {
    width: 140px;
  }

  .Profile {
    width: 80px;
    margin-left: 20px;
    margin-bottom: 7%;
  }
  .profile-name {
    font-size: 6.8pt;
  }
  .profile-position {
    font-size: 6.5pt;
  }
  .profile-explanation {
    font-size: 6pt;
  }
  .personal-email {
    font-size: 5pt;
  }
  .Profile2 {
    width: 80px;
    height: 80px;
    margin-bottom: 7%;
    margin-left: 25px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 320px) {
  .profile-box {
    margin-left: 30px;
  }

  .linkedin-icon .svg{
    height: 8px;
    width: 8px;
  }

  .mobile-profile {
    margin-left: 20%;
    margin-top: 3%;
    margin-bottom: 10%;
    width: 100%;
    overflow: hidden
  }
  .mobile-cat-profile {
    margin-left: 20%;
  }
  .Profile {
    width: 60px;
    margin-left: 20%;
    margin-bottom: 7%;
  }
  .profile-name {
    font-size: 5pt;
  }
  .profile-position {
    font-size: 5pt;
  }
  .profile-explanation {
    font-size: 5pt;
  }
  .personal-email {
    font-size: 5pt;
  }
  .profile-explanation {
    margin-bottom: 10px;
  }
  .Profile2 {
    width: 60px;
    height: 60px;
    margin-bottom: 7%;
    margin-left: 20%;
    border-radius: 50%;
  }
}